exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-content-parking-malaga-costa-del-sol-pages-index-js": () => import("./../../../src/content/parking-malaga-costa-del-sol/pages/index.js" /* webpackChunkName: "component---src-content-parking-malaga-costa-del-sol-pages-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-regulamin-js": () => import("./../../../src/pages/regulamin.js" /* webpackChunkName: "component---src-pages-regulamin-js" */),
  "component---src-pages-rodo-js": () => import("./../../../src/pages/rodo.js" /* webpackChunkName: "component---src-pages-rodo-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-jak-zarezerwowac-js": () => import("./../../../src/templates/jak-zarezerwowac.js" /* webpackChunkName: "component---src-templates-jak-zarezerwowac-js" */),
  "component---src-templates-parking-post-js": () => import("./../../../src/templates/parking-post.js" /* webpackChunkName: "component---src-templates-parking-post-js" */),
  "component---src-templates-parkingi-przy-lotniskach-page-js": () => import("./../../../src/templates/parkingi-przy-lotniskach-page.js" /* webpackChunkName: "component---src-templates-parkingi-przy-lotniskach-page-js" */)
}

